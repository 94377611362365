export default function initFixedHeader() {
    const header = document.querySelector('#header');
    const scrollDown = "scrolling-down";
    let lastScroll = 0;

    const init = () => {
        const body = document.querySelector('body');
        const headerHeight = document.querySelector('#header').offsetHeight;
        const top = window.pageYOffset || document.documentElement.scrollTop;
        const wrapper = document.querySelector('#main');

        if (body.classList.contains('homepage')) {
            if (top >= 1) {
                document.body.classList.add('fixed-header');
            } else {
                document.body.classList.remove('fixed-header');
            }
        } else {
            if (top >= 1) {
                document.body.classList.add('fixed-header');
                wrapper.style.paddingTop = `${headerHeight}px`;
            } else {
                document.body.classList.remove('fixed-header');
                wrapper.style.paddingTop = '0';
            }
        }
    };

    function checkPosition() {
        if (window.innerWidth <= 1023 && header) {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 0) {
                return;
            }

            if ((currentScroll - 4) > lastScroll && !header.classList.contains(scrollDown)) {
              header.classList.add(scrollDown);
            } else if ( currentScroll < (lastScroll - 4) && header.classList.contains(scrollDown)) {
                  header.classList.remove(scrollDown);
            }

            lastScroll = currentScroll;
        }
    }

    const scrollFunction = () => {
      if (window.innerWidth <= 1023) {
        checkPosition();
      } else {
        header.classList.remove(scrollDown);
      }
    };

    window.addEventListener('orientationchange', scrollFunction);
    window.addEventListener('scroll', scrollFunction);
    window.addEventListener('resize', scrollFunction);
    window.addEventListener('scroll', init);

    if (window.innerWidth > 1023) {
        window.removeEventListener('orientationchange', scrollFunction);
        window.removeEventListener('scroll', scrollFunction);
        window.removeEventListener('resize', scrollFunction);
    }

    scrollFunction();
    init();
}
