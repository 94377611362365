export default function initFixedBlock(blockSelector, holderSelector, breakpoint = 1023) {
  const btn = document.querySelector(blockSelector);
  const btnParent = btn?.offsetParent;
  const holder = document.querySelector(holderSelector);
  const fixedClass = "fixed-state";
  let parentTopOffset = 0;
  let windowHeight = 0;

  if (!btn || !btnParent || !holder) return;

  function init() {
    getCurrentValues();
    toggleClasses();
  }

  function setClass() {
    btn.classList.add(fixedClass);
  }

  function removeClass() {
    btn.classList.remove(fixedClass);
  }

  function getCurrentValues() {
    parentTopOffset = btnParent.getBoundingClientRect().bottom;
    windowHeight = window.innerHeight;
  }

  function toggleClasses() {
    parentTopOffset - windowHeight >= 0 ? setClass() : removeClass();
  }

  function workRange() {
    if (window.innerWidth <= breakpoint) {
      window.addEventListener("scroll", init);
    } else {
      removeClass();
      window.removeEventListener("scroll", init);
    }
  }

  window.addEventListener("resize", workRange);
  window.addEventListener("orientationchange", workRange);
  window.addEventListener("resize", init);
  window.addEventListener("orientationchange", init);

  workRange();
  init();
}
