import 'slick-carousel'

export default function initSlickCarousel () {
  window.ResponsiveHelper.addRange({
    '..1023': {
      on: () => {
        jQuery('.js-gallery-on-mobile').slick({
          slidesToScroll: 1,
          rows: 0,
          variableWidth: true,
          arrows: false,
          fade: false,
          autoplay: false,
          dots: false,
          accessibility: true,
          infinite: true
        })

        if (jQuery('.js-review-gallery-desktop .ti-reviews-container-wrapper').hasClass('slick-initialized')) jQuery('.js-review-gallery-desktop .ti-reviews-container-wrapper').slick('unslick')
      }
    },
    '1024..': {
      on: () => {
        if (jQuery('.js-gallery-on-mobile').hasClass('slick-initialized')) jQuery('.js-gallery-on-mobile').slick('unslick')

        jQuery('.js-review-gallery-desktop .ti-reviews-container-wrapper').slick({
          slidesToScroll: 1,
          slidesToShow: 3,
          accessibility: true,
          rows: 0,
          arrows: true,
          prevArrow: '<button class="slick-prev"><span class="visually-hidden">Previous review</span></button>',
          nextArrow: '<button class="slick-next"><span class="visually-hidden">Next review</span></button>',
          variableWidth: false,
          fade: false,
          autoplay: false,
          dots: false,
          infinite: false
        })
      }
    }
  })

  jQuery('.js-services-slider').slick({
    slidesToScroll: 1,
    slidesToShow: 4,
    rows: 0,
    variableWidth: true,
    fade: false,
    autoplay: false,
    accessibility: true,
    infinite: false,
    arrows: true,
    prevArrow: '<button class="slick-prev"><span class="visually-hidden">Previous review</span></button>',
    nextArrow: '<button class="slick-next"><span class="visually-hidden">Next review</span></button>',
		dots: true,
    appendArrows: $('.services-slider-holder .slick-navigation'),
    appendDots: $('.services-slider-holder .slick-navigation'),
		dotsClass: 'slick-dots',
		responsive: [{
			breakpoint: 1200,
			settings: {
				slidesToScroll: 1,
				slidesToShow: 3
			}
		},{
			breakpoint: 1024,
			settings: {
				slidesToScroll: 1,
				slidesToShow: 2,
        arrows: false,
        dots: false
			}
		},
    {
			breakpoint: 768,
			settings: {
				slidesToScroll: 1,
				slidesToShow: 1,
        arrows: false,
        dots: false
			}
		}]
  })

  jQuery('.js-service-gallery').slick({
    slidesToScroll: 1,
    rows: 0,
    variableWidth: true,
    fade: false,
    autoplay: true,
		autoplaySpeed: 1500,
		speed: 3500,
    accessibility: true,
    infinite: true,
    arrows: true,
    prevArrow: '<button class="slick-prev"><span class="visually-hidden">Previous review</span></button>',
    nextArrow: '<button class="slick-next"><span class="visually-hidden">Next review</span></button>'
  })

  jQuery('.js-category-slider').each(function() {
    jQuery(this).slick({
      slidesToScroll: 1,
      slidesToShow: 4,
      rows: 0,
      variableWidth: true,
      fade: false,
      autoplay: false,
      accessibility: true,
      infinite: false,
      arrows: true,
      prevArrow: '<button class="slick-prev"><span class="visually-hidden">Previous review</span></button>',
      nextArrow: '<button class="slick-next"><span class="visually-hidden">Next review</span></button>',
      dots: false,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
          arrows: false,
          variableWidth: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          arrows: false,
          variableWidth: true
        }
      }]
    })
  })
}
