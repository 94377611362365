import '../plugins/smoothAnchorLinksPlugin';

// initialize smooth anchor links
export default function initAnchors() {
	let direction = 0;

	new SmoothScroll({
		anchorLinks: '.anchor-link',
		onBeforeScroll: function( offset) {
			direction = offset < window.scrollY ? 1 : -1;
		},
		extraOffset: function() {
			var totalHeight = 0;
			jQuery('#header').each(function() {
				var $box = jQuery(this);
				var stickyInstance = $box.data('StickyScrollBlock');
				if (stickyInstance) {
					stickyInstance.stickyFlag = false;
					stickyInstance.stickyOn();
					totalHeight += $box.outerHeight();
					stickyInstance.onResize();
				} else {
					totalHeight += $box.css('position') === 'fixed' ? $box.outerHeight() : 0;
				}
			});

			if (window.innerWidth > 1023  || direction > 0) {
				return totalHeight;
			}
			return 0;

		},
		wheelBehavior: 'none'
	})
}
