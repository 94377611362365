export default function introVideoButton() {
  const introSection = document.querySelector('.intro-section');

  if (introSection) {
    const introVideoPlay = introSection.querySelector('.play-btn');
    const introVideo = introSection.querySelector('#introVideo');

    function playVideo() {
      introVideo.play();
      introSection.classList.add('bg-video-play');
    }

    function stopVideo() {
      introVideo.pause();
      introSection.classList.remove('bg-video-play');
    }

    function handleVideoPlay() {
      if (window.innerWidth <= 1023) {
        playVideo();
      } else if (introSection.classList.contains('bg-video') || introSection.classList.contains('bg-video-play')) {
        playVideo();
      } else {
        stopVideo();
      }
    }

    if (introVideoPlay) {
      introVideoPlay.addEventListener('click', () => {
        playVideo();
      });
    }

    handleVideoPlay();

    window.addEventListener("resize", () => {
      handleVideoPlay();
    });

    window.addEventListener("orientationchange", () => {
      handleVideoPlay();
    });
  }
};
