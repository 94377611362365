import '../plugins/popupPlugin'

// popups init
export default function initPopups() {
	jQuery('.js-base-popup-holder, body').contentPopup({
		mode: 'click',
		popup: '.js-base-popup',
		btnOpen: '.js-base-opener',
		btnClose: '.base-popup-close',
		openClass: 'base-popup-active',
		hideOnClickLink: false,
		hideOnClickOutside: false
	});
	jQuery('.js-booking-popup-holder, body').contentPopup({
		mode: 'click',
		popup: '.js-booking-popup',
		btnOpen: '.js-booking-opener',
		btnClose: '.booking-popup-close',
		openClass: 'booking-popup-active',
		hideOnClickLink: false,
		hideOnClickOutside: false
	});
	jQuery('.footer-nav .js-privacy-popup-holder, body').contentPopup({
		mode: 'click',
		popup: '.js-privacy-popup',
		btnOpen: '.privacy-popup-opener',
		btnClose: '.privacy-popup-close',
		openClass: 'privacy-popup-active',
		hideOnClickLink: false,
		hideOnClickOutside: false
	});
	jQuery('.footer-nav .js-terms-popup-holder, body').contentPopup({
		mode: 'click',
		popup: '.js-terms-popup',
		btnOpen: '.terms-popup-opener',
		btnClose: '.terms-popup-close',
		openClass: 'terms-popup-active',
		hideOnClickLink: false,
		hideOnClickOutside: false
	});
	ResponsiveHelper.addRange({
		'..1023': {
			on: function() {
				jQuery('.popup-calendar-holder, body').contentPopup({
					mode: 'click',
					popup: '.calendar-popup',
					btnOpen: '.calendar-open',
					btnClose: '.calendar-close',
					openClass: 'calendar-popup-active',
					hideOnClickLink: 'calendar-popup-active',
					hideOnClickOutside: 'calendar-popup-active'
				});
			},
			off: function() {
				jQuery('.popup-calendar-holder, body').contentPopup('destroy');
			}
		}
	});
}
