import '../plugins/accordionPlugin';
// accordion menu init
export default function initAccordion() {
	jQuery('.js-faq-accordion').slideAccordion({
		opener: '.faq-opener',
		slider: '.faq-slide',
		animSpeed: 300,
		activeClass: 'faq-active',
		scrollToActiveItem: {
			enable: true,
			extraOffset: 0
		}
	});
}
