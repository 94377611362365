import '../plugins/tabsPlugin'

// content tabs init
export default function initTabs() {
	jQuery('.js-filtration-tabset').tabset({
		tabLinks: 'a.main-category',
		autoHeight: false,
		defaultTab: true
	});
	jQuery('.js-sub-filtration-tabset').tabset({
		tabLinks: 'a.sub-category',
		autoHeight: false,
		defaultTab: true
	});
}