export default function reviewFakeLoadMore() {
  const holder = document.querySelector('.js-review-loadmore');

  if (holder) {
    const reviews = holder.querySelectorAll('.ti-review-item');
    const btn = holder.querySelector('.loadmore-btn-holder button');

    function showMore() {
      holder.classList.add('showing-more');
    }

    function init() {
      reviews.forEach((el) => {
        const height = el.offsetHeight;
        holder.style.height = `${height * 2}px`;
      })

      btn.addEventListener('click', showMore)
    }

    function stop() {
      holder.style.removeProperty('height');
      btn.removeEventListener('click', showMore);

      if (holder.classList.contains('showing-more')) {
        holder.classList.remove('showing-more');
      }
    }

    if (window.innerWidth <= 1024) {
      init();
    } else {
      stop();
    }
    
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1024) {
        init();
      } else {
        stop();
      }
    });

    window.addEventListener("orientationchange", () => {
      if (window.innerWidth <= 1024) {
        init();
      } else {
        stop();
      }
    });   
  }
};
