import '../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import ResizeHandler from './utils/resize-active'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin
import initFixedHeader from './components/fixedHeader'
import initPopups from './components/initPopup'
import introVideoButton from './components/introVideoButton'
import initMobileNav from './components/initMobileNav'
import initSlickCarousel from './components/initSlickCarousel'
import initAccordion from './components/initAccordion'
import initGoogleLinkAddress from './utils/googleLinkAddress'
import reviewFakeLoadMore from './components/reviewFakeLoadMore'
import initAnchors from './components/initSmoothAnchorLinks'
import fixedBlockInit from './components/initFixedBlock'
import initTabs from './components/initTabs'
import typingTextInit from './components/typingText'
import initInViewport from './components/initInViewport'

const resizeHandler = new ResizeHandler()

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()
  initFixedHeader()
  initPopups()
  introVideoButton()
  initMobileNav()
  initSlickCarousel()
  initAccordion()
  initGoogleLinkAddress()
  reviewFakeLoadMore()
  initAnchors()
  fixedBlockInit()
  initTabs()
  typingTextInit()
  initInViewport()
})
