import typingEffect from 'typing-effect';

export default function typingTextInit() {
  const targetNodes = document.querySelectorAll(".typing-block");
  targetNodes.forEach((el) => {
    
    const nextElements = document.querySelectorAll('.typing-block .typing-title')
    const observer =  new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            const el = mutation.target;
            
            if (mutation.target.classList && el.classList.contains("in-viewport")){
              nextElements[0].classList.add("typing-start");
            }
          });
        });
        
        nextElements.forEach((el, index) => {
        const observer =  new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            const el = mutation.target;
            
            if (mutation.target.classList && el.classList.contains("typing-start") && !el.classList.contains("typing-complete")){
                initTyping(el, index, nextElements );
                setTimeout(() => {
                  const elHeight = el.offsetHeight;
                  const parent = el.closest('.title-holder');
                  parent.style.height = `${elHeight}px`;
                }, 100);
              }
            });
          });
          
          observer.observe(el, {
            attributeFilter: ['class'] 
          });
        })
        
        observer.observe(el, {
          attributeFilter: ['class'] 
        });
    });
  
  function initTyping(el,index, nextElements ) {
    typingEffect(([el]), {
        speed: 135,
        delay: 400,
        reset: false,
    }).then(() => {
      if(index + 1 !== nextElements.length) {
        setTimeout(() => {
          el.classList.add('typing-complete');
          nextElements[index + 1].classList.add("next-text");
        }, 3000);
        setTimeout(() => {
          nextElements[index + 1].classList.add("typing-start");
        }, 3700);
      }
    });
  }
}
