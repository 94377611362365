import MobileNav from '../plugins/mobileNavPlugin';

export default function initMobileNav() {
  const menuHolder = document.querySelector('body');
  const menuAnchors = document.querySelectorAll('.anchor-link');
  const popupHolder = document.querySelector('.js-booking-popup-holder');
  const mobileNav = new MobileNav(menuHolder, {
    activeClass: 'nav-active',
    btnOpenerSelector: '.nav-opener',
    hideOnClickOutside: false,
    outSideSelector: '.mobile-menu-holder',
    onShow() {
      mobileNav.btnOpeners.forEach((btn) => {
        btn.setAttribute('aria-expanded', 'true');
      });
      document.addEventListener('keydown', handleKeyPress);

      if(menuAnchors.length) {
        menuAnchors.forEach((el) => {
          el.addEventListener('click', (e) => {
            e.preventDefault;
            mobileNav.hide();
            mobileNav.btnOpeners.forEach((btn) => {
              btn.setAttribute('aria-expanded', 'false');
            });
            mobileNav.btnOpeners[0].focus();
            document.removeEventListener('keydown', handleKeyPress);
          })
        });
      }

      if(popupHolder) {
        popupHolder.addEventListener('click', (e) => {
          mobileNav.hide();
            mobileNav.btnOpeners.forEach((btn) => {
              btn.setAttribute('aria-expanded', 'false');
            });
            mobileNav.btnOpeners[0].focus();
            document.removeEventListener('keydown', handleKeyPress);
        });
      }
    },
    onHide() {
      mobileNav.btnOpeners.forEach((btn) => {
        btn.setAttribute('aria-expanded', 'false');
      });
      mobileNav.btnOpeners[0].focus();
      document.removeEventListener('keydown', handleKeyPress);
    },
  });

  function handleKeyPress(event) {
    const keys = getKeyboardFocusableElements(mobileNav.outSideContainer);
    const firstItem = keys.slice(0)[0];
    const lastItem = keys.slice(-1)[0];
    let currentItem = document.activeElement;

    if (event.shiftKey && event.keyCode == 9) {
      if (currentItem == firstItem) {
        setTimeout(() => {
          lastItem.focus()
        }, 50)
        event.preventDefault();
      }
    } else if (event.key == "Tab") {
      if (currentItem == lastItem) {
        setTimeout(() => {
          firstItem.focus()
        }, 50)
        event.preventDefault();
      }
    } else if (event.key == "Escape") {
      mobileNav.hide();
    }
  }

  function getKeyboardFocusableElements(element = document) {
    return [
      ...element.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
      ),
    ].filter(
      el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
    )
  }

  mobileNav.init();
}
